import React, { Component, type ComponentType } from 'react';
import isEqual from 'lodash/isEqual';
import {
	ConnectIframe as ConnectIframeModuleCore,
	type ConnectHost,
} from '@atlassian/connect-module-core';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import DefaultConnectIFrameProvider from '@atlassian/jira-common-ecosystem/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useConnectItemClickHistory } from '@atlassian/jira-connect-item-click-history-controller/src/index.tsx';
import { setupConnectJs } from '@atlassian/jira-connect/src/index.tsx';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import fetchModuleConfiguration from '../services/index.tsx';
import type { AppDescriptor } from '../types.tsx';

type DefaultProps = {
	prefetchResult: null | AppDescriptor;
	TimeoutIndicator: ComponentType<{
		error: Error;
	}>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	LoadingIndicator: ComponentType<Record<any, any>>;
	frameHeight: string;
	frameWidth: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	queryParams: any;
};

export type Props = DefaultProps & {
	baseUrl: BaseUrl;
	addonKey: string;
	moduleKey: string;
	onLoadingComplete?: () => void;
	onTimeout?: () => void;
	onError?: () => void;
	connectHostDi?: ConnectHost;
};

export type State = {
	error: null | Error;
	connectHost: ConnectHost | undefined;
	options: undefined | AppDescriptor;
	loading: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class ConnectIframeView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const { prefetchResult, connectHostDi = !__SERVER__ ? window.connectHost : undefined } = props;

		this.state = {
			options: prefetchResult || undefined,

			connectHost: connectHostDi,
			loading: false,
			error: null,
		};
	}

	componentDidMount() {
		if (!this.state.options) {
			this.getData();
		}
		if (!this.state.connectHost && !__SERVER__) {
			this.loadHost();
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (
			!isEqual(prevProps.queryParams, this.props.queryParams) ||
			prevProps.addonKey !== this.props.addonKey ||
			prevProps.moduleKey !== this.props.moduleKey
		) {
			this.getData();
		}
	}

	getData() {
		this.setState({ loading: true });

		fetchModuleConfiguration(
			this.props.baseUrl,
			this.props.addonKey,
			this.props.moduleKey,
			this.props.queryParams,
		)
			.then((options) => {
				this.setState({ options });
			})
			.catch((error) => {
				this.setState({ error });
				log.safeErrorWithoutCustomerData(
					'connect-iframe',
					'Failed to fetch module configuration',
					error,
				);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	loadHost() {
		setupConnectJs()
			.then((connectHost) => {
				this.setState({
					connectHost,
				});
			})
			.catch((error) => {
				this.setState({ error });
				log.safeErrorWithoutCustomerData('connect-iframe', 'Failed to get connect host', error);
			});
	}

	connectIframeProvider = new DefaultConnectIFrameProvider({
		handleOnLoadingComplete: this.props.onLoadingComplete,
		handleOnTimeout: this.props.onTimeout,
	});

	render() {
		const { addonKey, moduleKey, frameHeight, frameWidth, TimeoutIndicator, LoadingIndicator } =
			this.props;

		const { options, connectHost, error, loading } = this.state;

		if (error) {
			if (this.props.onError) {
				this.props.onError();
			}
			return <TimeoutIndicator error={error} />;
		}
		return options && connectHost && !loading ? (
			<ConnectIframeModuleCore
				{...options}
				// @ts-expect-error - TS2739 - Type 'Readonly<Readonly<{ status?: number | undefined; addon_key?: string | undefined; uniqueKey?: string | undefined; ns?: string | undefined; moduleType?: string | undefined; moduleLocation?: string | undefined; ... 24 more ...; targets?: { ...; } | undefined; }> & { ...; }>' is missing the following properties from type 'AppOptions': widthinpx, hostFrameOffset, _contextualOperations
				options={options}
				height={frameHeight}
				width={frameWidth}
				connectHost={connectHost}
				// @ts-expect-error - TS2322 - Type 'ComponentType<Record<any, any>>' is not assignable to type 'typeof PureComponent'.
				loadingIndicator={LoadingIndicator}
				// @ts-expect-error - TS2322 - Type 'ComponentType<{ error: Error; }>' is not assignable to type 'new () => PureComponent<{ failedCallback: Function; }, {}, any>'.
				timeoutIndicator={TimeoutIndicator}
				appKey={addonKey}
				connectIframeProvider={this.connectIframeProvider}
				moduleKey={moduleKey}
				key={options.url}
			/>
		) : (
			<LoadingIndicator />
		);
	}
}

export const ConnectIframe = (props: Props) => {
	const { history } = useConnectItemClickHistory();
	return <ConnectIframeView {...props} key={history.unique.length} />;
};

ConnectIframe.defaultProps = {
	prefetchResult: null,
	frameHeight: '100%',
	frameWidth: '100%',
	TimeoutIndicator: ({ error }: { error: Error }) => (
		<ErrorFlagRenderer id="jira-connect" error={error} />
	),
	LoadingIndicator: Spinner,
	queryParams: {},
};

export default ConnectIframe;
